@use 'themes' as *;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap');

@import 'themes';
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import './layout';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
}

:root {
  --main-bg: #151a30;
  --color-active: #36f;
}

@import '../../../g-layout.scss';
@import '../../../g-dashboard.scss';
