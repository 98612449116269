@mixin manage-card {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
  }

  &__date {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__form {
    width: 100%;
    margin-top: 10px;

    .label {
      margin-bottom: 5px;

      &.warning {
        margin: 3px 0 0 0;
        font-weight: 600;
        color: #b5b538;
      }

      sup {
        color: #df0202;
      }
    }

    .form-group-row {
      display: flex;
      flex-flow: wrap;
      column-gap: 5px;

      .form-group-col {
        display: flex;
        align-items: flex-end;
        column-gap: 5px;
      }
    }

    .form-group {
      display: flex;
      flex-flow: column;
      margin-bottom: 20px;

      &.form-group-checkbox {
        justify-content: center;
        height: 40px;
      }

      input[type='file']::file-selector-button {
        background-color: #101426;
        color: white;
        border: none;
        padding: 0 6px;
        border-radius: 4px;
        cursor: pointer;
      }

      &__list-item {
        display: flex;
        align-items: center;
        position: relative;

        .col-fullwidth {
          width: 100%;
        }

        &.with-icon {
          padding-right: 30px;
        }

        .remove-item {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      }

      &__error {
        display: block;
        color: red;
        font-size: 12px;

        mark {
          padding: 0 4px;
          color: #fff;
          background: #8f0505;
          border-radius: 3px;
        }
      }

      .add-more {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;
      }
    }

    .group {
      padding: 20px 20px 0 20px;
      border: 1px solid #101426;
      border-radius: 5px;
    }

    .image-preview img {
      width: auto;
      max-width: 60%;
      padding: 10px;
      border-radius: 5px;
      background-color: #151a30;
      border-color: #101426;
    }

    .avatar {
      display: flex;
      align-items: center;

      &-preview {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-right: 7px;
        padding: 2px;
        border-radius: 50%;
        background-color: #151a30;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .form-submit {
      min-height: 48px;
      margin: 10px 0 10px 0;
      z-index: 1;
    }
  }
}

@mixin socials {
  &__social {
    display: flex;
    align-items: flex-start;

    img {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 7px;
      border-radius: 50%;
    }

    &-group {
      width: 100%;
      display: flex;
      flex-flow: column;
    }
  }
}

@mixin manage-card-label {
  width: 100%;

  &.half-width {
    width: 50%;
  }

  &-name {
    display: inline-block;
    font-size: 15px;
    line-height: 1em;
    color: #fff;
    padding-bottom: 5px;
  }

  &-input {
    width: 100%;
  }

  textarea {
    min-height: 100px;
    max-height: 250px;
  }
}

@mixin custom-scroll {
  -ms-overflow-style: scrollbar;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #192038;
    box-shadow: 0 0 1px #192038;
  }
}

@mixin custom-y-scroll {
  overflow-y: auto;
  @include custom-scroll;
}

@mixin custom-x-scroll {
  overflow-x: auto;
  @include custom-scroll;
}

@mixin page {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 40px;
  }

  &__add {
    width: 100%;
    max-width: 300px;
  }
}

@mixin bonus-balances() {
  display: flex;
  flex-flow: row;
  gap: 20px;

  &-item {
    width: 100%;
    display: flex;
    flex-flow: column;
    text-align: center;
    border: 1px solid var(--color-active);
    border-radius: 15px;
    overflow: hidden;

    .title {
      padding: 15px;
      font-weight: 600;
      background: var(--color-active);
    }

    .value {
      height: 100%;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin-left: auto;
      }
    }
  }
}

@mixin filter-form() {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 20px;
  padding: 5px 18px 0;
  border: 1px solid #151a30;
  border-radius: 5px;
  background: #1d253e;
  @include custom-x-scroll;
  @include manage-card;

  &__clear {
    position: sticky;
    margin-right: -13px;
  }

  form {
    display: flex;
    flex-flow: wrap;
    align-items: flex-end;
    column-gap: 5px;

    input,
    input::placeholder,
    nb-select::ng-deep span {
      font-size: 12px !important;
    }

    input {
      &.datepicker {
        width: 150px;
      }
    }

    nb-select {
      ::ng-deep {
        .select-button {
          min-width: auto;
          max-width: 190px;
        }

        nb-select-label {
          display: flex;
          align-items: center;
        }
      }

      &.wide-dropdown {
        min-width: 195px;
      }

      &.medium-dropdown {
        min-width: 130px;
      }

      &.sort-dropdown {
        width: 120px;

        nb-select-label {
          ::ng-deep {
            nb-icon {
              margin: 0 5px;
              font-size: 18px;

              &.rotated {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    nb-checkbox {
      margin: 0 5px;
    }

    button {
      width: 100px;
    }
  }
}

@mixin tabs() {
  &-container {
    border-bottom: 2px solid var(--main-bg);
    margin-bottom: 20px;

    .wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  &-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &-item {
    padding: 0 15px 12px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    &.active {
      color: var(--color-active);
      border-bottom: 2px solid var(--color-active);
    }

    &:hover {
      color: var(--color-active);
    }
  }
}
