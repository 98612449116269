@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import 'mixins';

nb-layout-header {
  border-bottom: 1px solid var(--main-bg);
}

nb-card {
  &.g-dashboard-table {
    padding: 30px;
  }

  &.g-dashboard-chart {
    padding: 0;
  }

  .table-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;

    @media (max-width: 767px) {
      flex-flow: column;
    }

    .wrapper {
      display: flex;
      align-items: center;

      nb-icon {
        color: var(--color-active);
        font-size: 36px;
      }
    }

    h2 {
      margin: 0;
      padding-left: 10px;
      color: #fff;
      font-family: 'Roboto';
      font-size: 30px;
      line-height: 35px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .buttons {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    button {
      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
}

.nb-theme-dark [nbButton]:focus {
  box-shadow: none !important;
}

.empty-template {
  margin: 40px 0 0 0;
  font-size: 18px;
  text-align: center;
}

table.table {
  width: 100%;
  color: #fff;
  text-align: left;
  border-collapse: collapse;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

  thead {
    th {
      padding: 11px;
      font-family: 'Oswald';
      color: #fff;
      font-size: 22px;
      line-height: 28px;
      font-weight: 400;
      background: rgba(21, 26, 48, 50%);
      border: 1px solid var(--main-bg);
      vertical-align: middle;

      &.action-buttons,
      &.nowidth {
        width: 0;
        white-space: nowrap;
      }

      .sort-icon {
        transition: 0.5s all;
        &.rotated {
          transform: rotate(180deg);
        }
      }
    }
  }

  tbody {
    td {
      min-height: 50px;
      padding: 15px 10px;
      color: #fff;
      background: transparent;
      border: 1px solid var(--main-bg);
      border-collapse: collapse;
      vertical-align: middle;

      &.text {
        word-break: break-word;
      }

      &.media {
        width: 150px;
        img {
          width: 100%;
          max-width: 150px;
        }
      }

      &.avatar {
        white-space: nowrap;
        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          margin-right: 5px;
          border-radius: 50%;
        }
      }

      &.avatar-list {
        img {
          width: 32px;
          height: 32px;
          object-fit: cover;
          margin: 3px 5px 3px 0;
          border-radius: 50%;
        }
      }

      &.teams-logo {
        white-space: nowrap;
        img {
          width: 32px;
          max-height: 32px;
          margin: 0 5px;
          object-fit: contain;
        }
      }

      &.action-buttons {
        white-space: nowrap;
        button:not(:last-child) {
          margin-right: 5px;
        }
      }

      &.inactive {
        opacity: 0.3;
      }

      .small {
        font-size: 11px;
      }

      &.center {
        text-align: center;
      }
    }

    tr {
      &.inactive {
        td:not(.action-buttons) {
          opacity: 0.3;
        }
      }
    }
  }

  .questionmark-title {
    th,
    td {
      position: relative;
      padding-right: 40px;

      nb-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 22px;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

nb-dialog-container {
  width: 100%;
}

.pagination-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-flow: column;
    align-items: center;
  }

  .entries {
    margin: 0 0 15px 0;
    font-size: 15px;
  }

  pagination-controls {
    ul {
      margin: 0;

      @media (max-width: 991px) {
        padding: 0;
      }

      li {
        min-width: 35px;
        margin: 0 0 0 -1px;
        padding: 0;
        color: #fff;
        background-color: transparent;
        border: 1px solid var(--main-bg);
        text-align: center;

        &.disabled {
          padding: 6px 12px;
          color: rgba(143, 155, 179, 0.5);
        }

        &.current {
          padding: 6px 12px;
          color: #fff;
          background-color: var(--main-bg);
          border-color: var(--main-bg);
        }

        &.small-screen {
          padding: 6px 12px;
        }

        a {
          padding: 6px 12px;
          color: #fff;
          text-decoration: none;
          &:hover {
            color: #fff;
            background-color: var(--main-bg);
          }
          &:focus {
            color: #fff;
          }
        }
      }
    }
  }
}

.player {
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.placeholder {
  opacity: 1;
}

// Dragula Drag and Drop

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

nb-progress-bar {
  .progress-container {
    background-color: #34415a !important;
  }
}

angular-editor {
  .angular-editor-toolbar {
    padding: 7px 8px 4px 8px !important;
    background: #192038 !important;
    border: 1px solid #101426 !important;
    border-radius: 5px;

    .angular-editor-toolbar-set {
      button {
        color: #fff;
        background-color: #3d4763 !important;
        border: 1px solid #101426 !important;
      }

      .angular-editor-button {
        margin-left: -1px;

        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      ae-select {
        &:hover {
          background: transparent !important;
        }

        .ae-picker-label {
          border-radius: 3px;

          &:before {
            background: transparent !important;
          }

          svg .ae-stroke {
            stroke: #fff;
          }
        }

        .ae-picker-options {
          border: none !important;
          background: transparent !important;

          .ae-picker-item {
            margin-top: -1px;

            &.selected {
              color: var(--color-active);
            }

            &:last-child {
              border-bottom-left-radius: 3px;
              border-bottom-right-radius: 3px;
            }
          }
        }
      }
    }
  }

  .angular-editor-textarea {
    background: #192038 !important;
    border: 1px solid #101426 !important;
    border-radius: 5px;

    code {
      background-color: transparent !important;
    }
  }
}

nb-option-list.size-medium {
  ul.option-list {
    @include custom-scroll;

    nb-option {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;

      span:not(.custom-checkbox) {
        margin-left: 5px;
        font-size: 11px;
      }

      &.empty,
      &.multiple.selected[disabled] {
        color: rgba(143, 155, 179, 0.48);
      }

      &.sort-option {
        nb-icon {
          margin: 0 5px;
          font-size: 16px;

          &.rotated {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.nb-theme-dark nb-popover {
  padding: 5px 10px;
  background: #485068;

  .arrow::after {
    border-bottom-color: #485068;
  }

  .balance-popover {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    min-height: 20px;

    img {
      height: 15px;
    }
  }
}

.ngx-slider {
  line-height: 2;

  .ngx-slider-span {
    &.ngx-slider-pointer {
      background-color: #3466ff;
    }

    &.ngx-slider-bar {
      background-color: #8f9bb3;
    }
  }

  .ngx-slider-bubble {
    &.ngx-slider-model-value {
      color: #fff;
    }
  }
}

nb-toast {
  .title {
    word-break: break-word;
  }
}

.tabset {
  .tab {
    width: 100%;

    .tab-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      line-height: 24px;
      font-family: 'Roboto';
      font-weight: 500;

      nb-icon {
        flex-shrink: 0;
        font-size: 24px;
      }
    }
  }
}
