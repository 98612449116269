.nb-theme-dark {
  nb-layout {
    padding-top: 0 !important;
    .scrollable-container {
      max-width: 100% !important;
      height: 100vh !important;
    }
    .layout {
      nb-layout-header {
        max-width: 100% !important;
        top: 0 !important;
        nav {
          max-width: 100% !important;
        }
      }
      .layout-container {
      }
    }
  }
}
